<template>
	<div class="static-edit-page">
		<BaseLoading v-if="isLoading" is-full-page />
		<FormPages
			v-else
			:is-edit-mode="true"
			:data="pageDetails"
			:page-list="pageList"
			:is-loading="isUpdating"
			:is-duplicate-slug="isDuplicateSlug"
			@onClearErrorSlug="handleClearErrorSlug"
			@onSubmit="handleSubmit"
			@onRemove="handleRemove"
		/>
		<BaseModalConfirmDelete
			ref="modal-confirm-delete"
			:handle-remove="deletePage.bind(null, id)"
			title="Remove this page?"
			description="Are you sure you want to delete this page?"
			@onSuccess="$router.push({ name: 'Pages' })"
		/>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import FormPages from '@/components/FormPages.vue';
import { pathOr, scrollToTop } from '../assets/js/helpers';

export default {
	name: 'PageEdit',
	components: {
		FormPages,
	},
	data() {
		return {
			id: this.$route.params.id,
			isLoading: false,
			isDuplicateSlug: false,
		};
	},
	computed: {
		...mapState('pages', {
			list: 'list',
			edit: 'edit',
		}),
		...mapGetters({
			pageList: 'pages/pageList',
			pageDetails: 'pages/pageDetails',
		}),

		isUpdating() {
			return this.edit.isUpdating;
		},
	},
	async created() {
		this.isLoading = true;

		await Promise.all([
			this.getPage(this.id),
			this.getPages(),
		]);

		this.isLoading = false;
	},
	methods: {
		...mapActions({
			getPages: 'pages/getPages',
			getPage: 'pages/getPage',
			updatePage: 'pages/updatePage',
			deletePage: 'pages/deletePage',
		}),
		async handleSubmit(params = {}) {
			try {
				await this.updatePage({
					id: this.id,
					params,
				});
			} catch (error) {
				this.isDuplicateSlug = !!(pathOr(false, ['response', 'data', 'errors', 'slug', 0])(error));
			} finally {
				scrollToTop();
			}
		},
		handleRemove() {
			this.$refs['modal-confirm-delete'].open();
		},
		handleClearErrorSlug() {
			this.isDuplicateSlug = false;
		},
	},
};
</script>

